@import "../../global.scss";


.works {

  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left{
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
      color: $maincolor;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      @include mobile {
        font-size: 20px;
        margin-top: 10px;
      }
    }

    h3{
      font-weight: 140;
      font-size: 30px;
      margin-left: 50px;
      margin-top: 40px;
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      @include mobile {
        margin-top: 30px;
        font-size: 17px;
      }

    }

  }

  .right{
    
    .imgContainer{
      
      width: 500px;
      height: 400px;
      margin-right: 90px;
      margin-top: 110px;
    
      border-radius: 15px;
      
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile {
        
        margin-left: 100px;
        margin-right: 100px;
        margin-bottom: 30npmpx;
        margin-top: 5px;
        width: 200px;
        height: 200px;
      }

    img{
      height: 100%;
    }
  }

  }

}